import { Image } from 'antd'
import { TranslateFunction } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'
import { NftDataType } from './type'

interface Props {
  dataNft: NftDataType
  t: TranslateFunction
}

const NftCard: React.FC<Props> = (props) => {
  const { dataNft, t } = props

  const nftDescription =
    dataNft?.attributes?.filter((data) => data.traitType === 'Description')?.[0]?.value || 'Description'

  return (
    <Container>
      <p className="text-[20px] font-bold w-full">{t("NFT Information")}</p>
      <NftWrapper style={{ backgroundImage: `url('/images/bg/nft-${dataNft?.attributes?.find(x => x?.traitType === 'Rare')?.value}.png')` }}>
        <InforText>
          <p className="text-[12px] text-[#1C1B1D] font-semibold">{t("Name")}:</p>
          <p className="text-[12px] text-[#1C1A23] font-bold text-right">{dataNft?.name?.toLocaleUpperCase()}</p>
        </InforText>
        <InforText>
          <p className="text-[12px] text-[#1C1B1D] font-semibold">ID:</p>
          <p className="text-[12px] text-[#1C1A23] font-bold text-right">{`#${dataNft?.id}`}</p>
        </InforText>
        <Image src={dataNft?.image} preview={false} width="100%" height="100%" alt="" />
      </NftWrapper>

      <Text>{nftDescription}</Text>
    </Container>
  )
}

export default React.memo(NftCard)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 18px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const InforText = styled.div`
  position: relative;
  z-index: 2;
  top: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 12px;
  width: 100%;
`
const Text = styled.p`
  font-size: 14px;
  text-align: left;
  width: 100%;
`

const NftWrapper = styled.div`
  width: 100%;
  padding: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .ant-image {
    img {
      border-radius: 12px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`
