import { Modal } from 'antd'
import { CloseButton } from 'components/ModalConnect'
import NftCard from 'components/ModalNFT/NftCard'
import { useTranslation } from 'contexts/Localization'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { setModalMintNft } from 'state/modal/actions'
import styled from 'styled-components'
import { TextGradient } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import ImageButton from 'widgets/BaseButton/ImageButton'

const NFT_STATUS_COLOR = {
  Prize: 'linear-gradient(180deg, #FFB905 37.76%, #FFEE97 62.67%, #FFB905 87.75%)',
  Missed: 'linear-gradient(180deg, #B6B6B6 37.76%, #FFF 62.67%, #B6B6B6 87.75%)',
  Jackpot: 'linear-gradient(180deg, #FFB905 37.76%, #FFEE97 62.67%, #FFB905 87.75%)',
}

const ModalMintNft: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const route = useRouter()
  const { toggle, dataModal } = useSelector((state: AppState) => state.modal.modalMintNft)

  const handleCancel = () => {
    dispatch(setModalMintNft({ toggle: false, dataModal: null }))
  }

  const handleClaim = () => {
    dispatch(setModalMintNft({ toggle: false, dataModal: null }))
    route.push('/my-nft/claim')
  }

  return (
    <Modal open={toggle} centered width={760} footer={null} closeIcon={<CloseButton />} onCancel={handleCancel}>
      <ModalContent>
        <BoxBackground className="flex mt-[-24px] w-fit mx-auto translate-y-[-50%]">
          <ImageButton className="py-2 px-4 md:min-w-[300px]">
            <TextGradient
              fontSize="24px"
              className="uppercase text-center"
              color={NFT_STATUS_COLOR?.[dataModal?.nft?.status]}
              style={{ fontFamily: `"Ultra", serif` }}
            >
              {dataModal?.nft?.status?.toUpperCase()}
            </TextGradient>
          </ImageButton>
        </BoxBackground>

        <div className="flex flex-col items-center gap-[60px]">
          <div className="flex items-center justify-center relative w-full">
            <img
              src="/images/bg/mint-modal-bg.png"
              alt=""
              className="md:w-[60%] w-full absolute bottom-0 translate-y-[33%] translate-x-[2.5%]"
            />

            <div className="md:max-w-[230px] max-w-[200px] mx-auto">
              <NftCard dataNft={dataModal?.nft as any} />
            </div>
          </div>

          <CardRight>
            <p className="p-3 rounded-[12px] bg-[#00000070] text-center max-w-[420px] mx-auto">
              {t("You agree to")} <span className="text-[#F90]">{t("Terms of Service")}</span> {t("and acknowledge that you have read and understand the")} <span className="text-[#F90]">{t("disclaimer")}</span>.
            </p>

            <div className="mb-[-24px] translate-y-[50%]">
              {dataModal?.nft?.status !== 'Missed' ? (
                <BaseButton
                  className="!py-4"
                  background="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)"
                  borderColor="rgba(255, 153, 0, 0.32)"
                  onClick={handleClaim}
                >
                  <p className="text-[20px] text-center text-white">{t("Claim Reward")}</p>
                </BaseButton>
              ) : (
                <BaseButton
                  className="!py-4"
                  background="linear-gradient(125deg, #0D021C -23.77%, #0F1841 67.33%)"
                  borderColor="#FFF"
                  onClick={handleCancel}
                >
                  <TextGradient fontSize='20px' color="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)">{t("Try Again")}</TextGradient>
                </BaseButton>
              )}
            </div>
          </CardRight>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default ModalMintNft

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`
const CardRight = styled.div`
  display: grid;
  grid-gap: 16px;
  height: fit-content;
`

const BoxBackground = styled.div`
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 140px 0px rgba(34, 7, 195, 0.44);
`
