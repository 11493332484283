import React from 'react'
import styled from 'styled-components'
import { Link } from 'widgets'
import { Login, Config } from './types'
import { connectorLocalStorageKey } from '../../../packages/uikit/src/widgets/WalletModal'

const WalletStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 15px;
  width: 100%;
  padding: 12px;
  border-radius: 36px;
  cursor: pointer;
  height: 100%;

  border: 1px solid #8F90A6;
  background: rgba(13, 13, 13, 0.24);
  background: #0B0D27;
  transition: 0.3s ease;

  .modal-title {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.04em;
  }

  svg {
    transition: 0.3s ease;
    width: 32px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    &:hover {
      transform: scale(1.03);
    }
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig

  let linkAction: any = {
    onClick: () => {
      login(walletConfig.connectorId)
      window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
      onDismiss()
    },
  }

  if (typeof window !== 'undefined' && !window.ethereum && walletConfig.href) {
    linkAction = {
      style: {
        textDecoration: 'none',
      },
      as: Link,
      href: walletConfig.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <WalletStyled id={`wallet-connect-${title.toLocaleLowerCase()}`} {...linkAction}>
      <div className="modal-title">{title}</div>
      <Icon width="40px" />
    </WalletStyled>
  )
}

export default WalletCard
