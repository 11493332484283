/* eslint-disable no-new */
/* eslint-disable dot-notation */
import { ResetCSS } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import BigNumber from 'bignumber.js'
import ModalConnect from 'components/ModalConnect'
import ModalMintNft from 'components/ModalMintNft'
import ModalMyNft from 'components/ModalNFT/ModalMyNft'
import { ToastListener } from 'contexts/ToastsContext'
import PublicLayout from 'layouts/PublicLayout'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { Blocklist } from '..'
import Providers from '../Providers'
import ErrorBoundary from '../components/ErrorBoundary'
import GlobalStyle from '../style/Global'
import '../style/animation.css'
import '../style/global.css'

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="https://dapp.babycate.org/favicon.ico?v=1.1" />
        <link rel="shortcut icon" type="image/png" href="https://dapp.babycate.org/favicon.ico?v=1.1" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="WELCOME TO BABYCATE AND HIS DARING JOURNEY" />
        <meta
          property="og:description"
          content="BABYCATE will bring investors a completely new journey in the Memecoin world with bold plans and huge profits."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="WELCOME TO BABYCATE AND HIS DARING JOURNEY" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="BABYCATE LOTTERY" />
        <meta property="og:image" content="https://dapp.babycate.org/graph.png" />
        <meta property="og:url" content="https://dapp.babycate.org" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="WELCOME TO BABYCATE AND HIS DARING JOURNEY" />
        <meta
          name="twitter:description"
          content="BABYCATE will bring investors a completely new journey in the Memecoin world with bold plans and huge profits."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@BABYCATE" />
        <meta name="twitter:image" content="https://dapp.babycate.org/graph.png" />
        <meta name="twitter:creator" content="@BABYCATE" />

        {/* <!-- Title --> */}
        <title>WELCOME TO BABYCATE AND HIS DARING JOURNEY</title>
        <meta name="keywords" content="WELCOME, TO BABYCATE, AND HIS DARING JOURNEY" />
        <meta
          name="description"
          content="BABYCATE will bring investors a completely new journey in the Memecoin world with bold plans and huge profits."
        />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <ResetCSS />
          <GlobalStyle />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
  authLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { account } = useWeb3React()
  const Layout = Fragment

  return (
    <ProductionErrorBoundary>
      <Layout>
        {Component.fullLayout ? (
          <Component {...pageProps} />
        ) : (
          <PublicLayout isAuth={Component?.authLayout} isLogged={account}>
            <div id="google_translate_element" />
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
      <ToastListener />
      <ModalConnect />
      <ModalMintNft />
      <ModalMyNft />
    </ProductionErrorBoundary>
  )
}

export default MyApp
