/* eslint-disable jsx-a11y/aria-role */
import { Spin } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Button = styled.div<{bg?: string}>`
  color: #fff;
  position: relative;
  white-space: nowrap;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 14px;
 
  div[role="background"] {
    position: absolute;
    inset: 0;
    content: ${({bg}) => `url(${bg})`};
    width: 100%;
    height: 100%;
  }

  div[role='button'] {
    position: relative;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

  }

  &.disabled {
    filter: grayscale(1);
  }
`

const ImageButton = ({
  children,
  onClick,
  className,
  loading,
  bg = '/images/bg/base-header-bg.png'
}: {
  children: ReactNode
  onClick?: () => void
  width?: string
  className?: string
  loading?: boolean
  bg?: string
}) => {
  return (
    <Button onClick={onClick} className={className} bg={bg}>
      <div role='background' />
      <div role='button'>
        {loading && <Spin spinning />} {children}
      </div>
    </Button>
  )
}

export default ImageButton
