import { META_NFT_BSC } from 'config/constants/endpoints'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

export const fetchUserNft = async (address: string) => {
  const qStr = qs.stringify({
    limit: 1000,
    skip: 0,
    owner: address,
  })

  return fetchHelper.fetch(`${META_NFT_BSC}/list?${qStr}`, {
    method: 'GET',
  })
}

function getUserNft(payload: { userAddress: string }, cbs?: any, cbe?: any) {
  const { userAddress } = payload
  try {
    fetchUserNft(userAddress).then(([resp, status]: any) => {
      if (status === 200) {
        if (cbs) cbs(resp.data)
      } else if (cbe) cbe(resp)
    })
  } catch (error) {
    console.error('getUserNft', error)
  }

  return null
}

export default getUserNft
