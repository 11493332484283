import { useWeb3React } from '@web3-react/core'
import { Col, Modal, Row } from 'antd'
import InputCopy from 'components/CoppyItem/InputCopy'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import Image from 'next/image'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled from 'styled-components'
import { TextGradient } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import { AppState } from '../../state/index'
import WalletCard from './WalletCard'
import config from './config'

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 24px;
  }

  .description {
    font-style: normal;
    font-size: 14px;
    text-align: center;
  }
`

const WrapperAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const CloseButton = () => (
  <div className="bg-[#0B0D27] rounded-full p-2 box-border-1 border-[rgba(255, 255, 255, 0.00)]" style={{boxShadow: "1px 1px 10px #000"}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M10.4536 10.4535C10.5581 10.3487 10.6822 10.2656 10.8189 10.2089C10.9556 10.1522 11.1021 10.123 11.2501 10.123C11.398 10.123 11.5446 10.1522 11.6812 10.2089C11.8179 10.2656 11.9421 10.3487 12.0466 10.4535L18.0001 16.4092L23.9536 10.4535C24.0582 10.3489 24.1823 10.2659 24.319 10.2093C24.4557 10.1527 24.6021 10.1236 24.7501 10.1236C24.898 10.1236 25.0445 10.1527 25.1811 10.2093C25.3178 10.2659 25.442 10.3489 25.5466 10.4535C25.6512 10.5581 25.7341 10.6823 25.7907 10.8189C25.8473 10.9556 25.8765 11.1021 25.8765 11.25C25.8765 11.3979 25.8473 11.5444 25.7907 11.6811C25.7341 11.8177 25.6512 11.9419 25.5466 12.0465L19.5908 18L25.5466 23.9535C25.6512 24.0581 25.7341 24.1823 25.7907 24.3189C25.8473 24.4556 25.8765 24.6021 25.8765 24.75C25.8765 24.8979 25.8473 25.0444 25.7907 25.1811C25.7341 25.3177 25.6512 25.4419 25.5466 25.5465C25.442 25.6511 25.3178 25.7341 25.1811 25.7907C25.0445 25.8473 24.898 25.8764 24.7501 25.8764C24.6021 25.8764 24.4557 25.8473 24.319 25.7907C24.1823 25.7341 24.0582 25.6511 23.9536 25.5465L18.0001 19.5907L12.0466 25.5465C11.942 25.6511 11.8178 25.7341 11.6811 25.7907C11.5445 25.8473 11.398 25.8764 11.2501 25.8764C11.1021 25.8764 10.9557 25.8473 10.819 25.7907C10.6823 25.7341 10.5582 25.6511 10.4536 25.5465C10.349 25.4419 10.266 25.3177 10.2094 25.1811C10.1528 25.0444 10.1236 24.8979 10.1236 24.75C10.1236 24.6021 10.1528 24.4556 10.2094 24.3189C10.266 24.1823 10.349 24.0581 10.4536 23.9535L16.4093 18L10.4536 12.0465C10.3488 11.942 10.2657 11.8178 10.209 11.6812C10.1522 11.5445 10.123 11.398 10.123 11.25C10.123 11.102 10.1522 10.9555 10.209 10.8188C10.2657 10.6821 10.3488 10.558 10.4536 10.4535Z"
        fill="white"
      />
    </svg>
  </div>
)

const ModalConnect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { toggle } = useSelector((state: AppState) => state.modal.modalConnect)

  const handleCancel = () => {
    dispatch(setModalConnect({ toggle: false }))
  }
  const handleLogout = () => {
    logout()
    handleCancel()
  }

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={600}
      footer={null}
      closeIcon={<CloseButton />}
      onCancel={handleCancel}
    >
      {account ? (
        <ModalContent>
          <WrapperAccount>
            <div className="title">{t("YOUR WALLET")}</div>
            <InputCopy title={t("Your address")} value={account} />
            <BaseButton className="btn-logout" onClick={handleLogout}>
              {t("Logout")}
            </BaseButton>
          </WrapperAccount>
        </ModalContent>
      ) : (
        <div className='flex items-center h-full'>
          <ModalContent>
            <div className='flex items-center gap-2 mx-auto flex-nowrap justify-center'>
              <Image src="/images/icons/cat-foot-icon.png" width={28} height={28} />
              <TextGradient className='!text-[20px] uppercase !font-bold' textAlign="center" color="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)">{t("Connect to a Wallet")}</TextGradient>
              <Image src="/images/icons/cat-foot-icon.png" width={28} height={28} />
            </div>
            <div className="description">
              {t("By connecting a wallet, you agree to")} <span className='text-[#F90]'>{t("Terms of Service")}</span> {t("and acknowledge that you have read and understand the")} <span className='text-[#F90]'>{t("disclaimer")}</span>.
            </div>
            <Row gutter={[12, 12]}>
              {config.map((entry) => (
                <Col key={entry.title} span={24}>
                  <WalletCard key={entry.title} walletConfig={entry} login={login} onDismiss={handleCancel} />
                </Col>
              ))}
            </Row>
          </ModalContent>
        </div>
      )}
    </Modal>
  )
}

export default React.memo(ModalConnect)
