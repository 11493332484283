import { Layout } from 'antd'
import Menu from 'components/Menu'
import { Box } from 'widgets'
import { useRouter } from 'next/router'

const { Content } = Layout

const PublicLayout = ({ children, isAuth, isLogged }) => {
  const router = useRouter()
  const isNotCheckAuth = ['/', '/home']

  if (isLogged || isNotCheckAuth.includes(router?.asPath) || isAuth) {
    return (
      <Layout>
        {/* <div id="google_translate_element" /> */}
        <Menu />
        <Content>
          <Box minHeight='100vh' position="relative">
            {children}
          </Box>
        </Content>
      </Layout>
    )
  }

  router.replace('/')
  return <></>
}

export default PublicLayout
