import { ROOT_DOMAIN } from 'config/constants'
import { ConnectorNames } from 'connectors'
import { isMobile } from 'react-device-detect'
import BinanceChain from './icons/BinanceChain'
import Metamask from './icons/Metamask'
import SafePalWallet from './icons/SafePalWallet'
import TrustWallet from './icons/TrustWallet'
import WalletConnect from './icons/WalletConnect'
import { Config } from './types'

const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    href: `https://metamask.app.link/dapp/${ROOT_DOMAIN}`,
  },
  {
    title: 'Wallet Connect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected,
    href: `https://link.trustwallet.com/open_url?url=${ROOT_DOMAIN}`,
  },
  {
    title: 'Binance Wallet',
    icon: BinanceChain,
    connectorId: (typeof window !== 'undefined' && !!window.ethereum && isMobile) ? ConnectorNames.Injected : ConnectorNames.BSC,
    href: "https://www.binance.com/en/web3wallet",
  },
  {
    title: 'SafePal Wallet',
    icon: SafePalWallet,
    connectorId: ConnectorNames.Injected,
  },
]

export default connectors
