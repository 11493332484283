const SWR_KEY = {
	GET_WINNER_TICKET: 'getWinnerTicket',
	GET_MY_TICKET: 'getMyTicket',
	GET_MY_NFT: 'getMyNft',
	GET_ALL_NFT: 'getAllNft',
	GET_TICKET_PRICE: 'getTicketPrice',
	GET_LIMIT_REDEEM: 'getLimitRedeem',
	GET_BUY_TICKET_REPORT: 'getBuyTicketReport',
	GET_TOTAL_POOL: 'getTotalPool',
}

export default SWR_KEY
