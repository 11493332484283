import { MenuOutlined } from '@ant-design/icons'
import { LanguageIcon } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown, Grid } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import useTokenBalance from 'hooks/useTokenBalance'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect, setModalMyNft } from 'state/modal/actions'
import styled from 'styled-components'
import { getBabyCateTokenAddress } from 'utils/addressHelpers'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'
import { Box, Link, TextGradient } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import { MenuEntry } from './types'

const { useBreakpoint } = Grid

interface Props {
  menuItems?: MenuEntry[]
  hasSider?: boolean
}

const LANG_LIST = [
  { locale: 'en-US', language: 'English', code: 'en' },
  { locale: 'zh-CN', language: '简体中文', code: 'zh-cn' },
]

const UserMenu = ({ tokenBalance, toggleMenuMyNft, breakPoint, account, toggleWallet, t }) => {
  return (
    <div className="flex items-center md:space-x-3 space-x-1">
      <div className="flex items-center w-fit gap-2">
        <p className="lg:text-[28px] md:text-[20px] text-base font-bold flex-1">{formatAmountOrigin(+tokenBalance / 1e9)}</p>
        <img src="/logo.png" alt="" className="max-w-[32px] aspect-square flex-shrink-0" />
      </div>
      <BaseButton className="!border-[#FFC773] lg:min-w-[140px]" onClick={toggleMenuMyNft} background="transparent">
        <TextGradient
          fontSize={breakPoint?.lg ? '20px' : '14px'}
          fontWeight={700}
          color="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)"
        >
          {t("My")} NFT
        </TextGradient>
      </BaseButton>
      <BaseButton className="auth-btn sign-in lg:min-w-[140px]" onClick={toggleWallet}>
        {formatCode(account, 5, 5)}
      </BaseButton>
    </div>
  )
}

const LangSelect = ({currentLanguage, setLanguage}) => {
  return (
    <Dropdown
      menu={{
        items: LANG_LIST.map((item) => ({
          key: item.code,
          label: (
            <Box className="text-center py-[6px] px-4 font-semibold" onClick={() => setLanguage(item)}>
              {item.language.toUpperCase()}
            </Box>
          ),
        })),
      }}
      placement="bottomRight"
      trigger={['click']}
    >
      <LangButton className="flex justify-center items-center gap-2 cursor-pointer px-3 py-2 lg:min-w-[120px]">
        <p className="uppercase font-semibold lg:text-base text-xs whitespace-nowrap">{currentLanguage?.language}</p>
        <LanguageIcon />
      </LangButton>
    </Dropdown>
  )
}

const Header: React.FC<Props> = ({ hasSider = false }) => {
  const { t, currentLanguage, setLanguage } = useTranslation()
  const dispatch = useDispatch()
  const breakPoint = useBreakpoint()
  const { account } = useWeb3React()

  const [isScroll, setIsScroll] = useState(false)
  // const userName = useGetUsernameBySpaceID(account)

  const { balance: tokenBalance } = useTokenBalance(getBabyCateTokenAddress())

  const toggleWallet = () => dispatch(setModalConnect({ toggle: true }))
  const toggleMenuMyNft = () => dispatch(setModalMyNft({ toggle: true }))

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  }

  const menuMobileItems = [
    {
      key: '1',
      label: (
        <BaseButton className="auth-btn sign-in min-w-[140px]" onClick={toggleWallet}>
          {formatCode(account, 5, 5)}
        </BaseButton>
      ),
    },
    {
      key: '2',
      label: (
        <BaseButton className="my-nft-btn min-w-[140px]" onClick={toggleMenuMyNft} background="#000000">
          <TextGradient
            fontSize={breakPoint?.lg ? '20px' : '14px'}
            color="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)"
          >
            {t("My")} NFT
          </TextGradient>
        </BaseButton>
      ),
    },
    {
      key: '3',
      label: (
        <div className="flex items-center justify-center gap-1">
          <p className="text-[20px] font-bold text-left">{formatAmountOrigin(+tokenBalance / 1e9)}</p>
          <img src="/logo.png" alt="" className="md:max-w-[36px] max-w-[24px] aspect-square flex-shrink-0" />
        </div>
      ),
    },
  ]

  useEffect(() => {
    changeBackground()
    window.addEventListener('scroll', changeBackground)
  })

  return (
    <WrapperHeader className={isScroll ? 'scroll' : ''}>
      <div className="content">
        {!hasSider ? (
          <div className="menu-left">
            <Link href="/">
              <span className="flex items-center gap-3">
                <Image src="/logo.png" width={55} height={55} />
                {breakPoint?.sm && <Image src="/logo-text.png" width={154} height={55} />}
              </span>
            </Link>
          </div>
        ) : (
          <div />
        )}

        <div className="menu-right gap-3">
          <LangSelect currentLanguage={currentLanguage} setLanguage={setLanguage} />

          {!breakPoint?.md ? (
              account ? (
                <Dropdown menu={{ items: menuMobileItems }} placement="bottomRight" trigger={['click']}>
                  <MenuOutlined rev={{ type: 'data' }} className="mobile-menu-icon" />
                </Dropdown>
              ) : (
                <BaseButton className="auth-btn sign-in" onClick={toggleWallet}>
                  {t('Connect Wallet')}
                </BaseButton>
              )
          ) : (
            <div className="menu-actions-desktop">
              {!account ? (
                <BaseButton className="auth-btn sign-in" onClick={toggleWallet}>
                  {t('Connect Wallet')}
                </BaseButton>
              ) : (
                <UserMenu
                  tokenBalance={tokenBalance}
                  toggleMenuMyNft={toggleMenuMyNft}
                  breakPoint={breakPoint}
                  account={account}
                  toggleWallet={toggleWallet}
                  t={t}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </WrapperHeader>
  )
}

export default Header

const WrapperHeader = styled.header<{ background?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  transition: 0.25s;
  z-index: 15;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;

  &.scroll {
    background: #0d163a;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    width: 100%;
    max-width: 1232px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 20px 16px;
    }

    .menu-left {
      img {
        width: 140px;

        ${({ theme }) => theme.mediaQueries.sm} {
          width: 180px;
        }
      }
    }

    .menu-right {
      display: flex;
      align-items: center;

      .menu-actions-desktop {
        position: relative;
        display: flex;
        align-items: center;
      }

      .auth-btn .my-nft-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        line-height: 1;
        font-weight: 700;
        font-size: 14px;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 20px;
        }

        &.sign-up {
          margin-left: 24px;
        }
      }

      .mobile-menu-icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      ${({ theme }) => theme.mediaQueries.md} {
        line-height: 30px;
      }
    }
  }
`

const LangButton = styled.div`
  border-radius: 10px;
  border: 2px solid #ffc773;
  background: linear-gradient(114deg, #0d021c -43.49%, #112161 90.99%);
  box-shadow: 0px 0px 140px 0px rgba(34, 7, 195, 0.44);
  backdrop-filter: blur(1.4px);
`
