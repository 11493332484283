import { DownOutlined } from '@ant-design/icons'
import { useWeb3React } from '@web3-react/core'
import { Button, Col, Dropdown, Empty, Grid, Modal, Row } from 'antd'
import { CloseButton } from 'components/ModalConnect'
import { useTranslation } from 'contexts/Localization'
import { useGetMyNftSwr } from 'hooks/swr/useGetMyNftSwr'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { setModalMyNft } from 'state/modal/actions'
import styled from 'styled-components'
import { TextGradient } from 'widgets'
import NftCard from './NftCard'
import NftCardInformation from './NftCardInformation'
import { NftDataType } from './type'

const { useBreakpoint } = Grid

const CustomDropdown = ({ setKeySelected }: { setKeySelected: (key: string) => void }) => {
  const [showKey, setShowKey] = useState('All')
  const dropdownKey = [
    { label: 'All', key: 'All' },
    { label: 'Jackpot', key: 'Jackpot' },
    { label: 'Prize', key: 'Prize' },
    { label: 'Missed', key: 'Missed' },
  ]

  const handleUpdate = (e) => {
    setShowKey(e.key)
    setKeySelected(e.key)
  }

  return (
    <Dropdown menu={{ items: dropdownKey, selectable: true, defaultSelectedKeys: ['All'], onClick: handleUpdate }}>
      <WrapperLink>
        {showKey}
        <DownOutlined rev />
      </WrapperLink>
    </Dropdown>
  )
}

const ModalMyNft = () => {
  const { toggle } = useSelector((state: AppState) => state.modal.modalMyNft)

  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const { t } = useTranslation()

  const { data: myNft } = useGetMyNftSwr(account)

  const [idxSelected, setIdxSelected] = useState(0)
  const [keySelected, setKeySelected] = useState('All')
  const [dataRender, setDataRender] = useState<Array<NftDataType> | undefined>([])

  const handleClose = () => dispatch(setModalMyNft({ toggle: false }))
  const handleSelectNft = (targetIdx) => setIdxSelected(targetIdx)

  useEffect(() => {
    if (!myNft) return

    if (keySelected === 'All') setDataRender(myNft)
    else {
      const filterNft = myNft?.filter((data) => {
        const nftRare = data.attributes.filter((currData) => currData.traitType === 'Rare')[0].value || 'Rare'
        return nftRare === keySelected
      })
      setDataRender(filterNft)
    }
  }, [keySelected, myNft])

  return (
    <Modal
      key="modal-my-nft"
      open={toggle}
      centered
      width={1100}
      footer={null}
      closeIcon={<CloseButton />}
      onCancel={handleClose}
    >
      <p className="md:text-[32px] text-[20px] text-[#F6F8FA] font-bold">{t("My")} NFT</p>
      <Container>
        <Row gutter={[20, 20]}>
          <Col span={24} md={{ span: 15 }} lg={{ span: 17 }}>
            <LeftSideWrapper>
              <ContainerHeader>
                <div className="flex items-center space-x-3">
                  <p className="text-[#A1A1AA]">{t("Total")}:</p>
                  <TextGradient fontSize="20px" bold color="linear-gradient(32deg, #FFC773 -7.07%, #F90 84.5%)">
                    {dataRender?.length} NFT
                  </TextGradient>
                </div>
                <CustomDropdown setKeySelected={setKeySelected} />
              </ContainerHeader>
              <TableData>
                <Row gutter={[breakpoint?.md ? 16 : 10, breakpoint?.lg ? 24 : 10]}>
                  {!dataRender?.length ? (
                    <div className="flex items-center justify-center w-full">
                      <Empty />
                    </div>
                  ) : (
                    dataRender?.map((item, index) => (
                      <Col
                        key={`${item.name} + ${item.id}`}
                        onClick={() => handleSelectNft(index)}
                        span={12}
                        lg={{ span: 8 }}
                        className={index === idxSelected ? 'active' : ''}
                      >
                        <NftCard dataNft={item} isSelected={index === idxSelected} />
                      </Col>
                    ))
                  )}
                </Row>
              </TableData>
            </LeftSideWrapper>
          </Col>

          {idxSelected !== -1 && dataRender.length > 0 && (
            <Col span={0} md={{ span: 9 }} lg={{ span: 7 }}>
              <RightSideWrapper>
                <div className="">
                  <NftCardInformation dataNft={dataRender[idxSelected]} t={t}/>
                </div>
              </RightSideWrapper>
            </Col>
          )}
        </Row>
      </Container>
    </Modal>
  )
}

export default ModalMyNft

const Container = styled.div`
  .ant-col {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid transparent;
    border-radius: 6px;

    &.active {
      border-color: #ffb905;
    }
  }
`

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  height: 36px;

  .wrapper-label-text {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 12px;
  }
`

const TableData = styled.div`
  padding: 0 6px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: 350px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px;
    border-radius: 24px;
    background: #0a011e;
    max-height: 500px;
  }
`

const RightSideWrapper = styled.div`
  border-radius: 24px;
  background: #0a011e;
  padding: 24px 16px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 24px;
  }
`
const WrapperLink = styled(Button)`
  border: 1px solid #ffffff;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  width: 120px;
  min-width: 60px;
`
