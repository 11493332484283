import { Image } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { NftDataType } from './type'

interface Props {
  dataNft: NftDataType
  isSelected?: boolean
  onClick?: () => void
}

const NftCard: React.FC<Props> = ({ dataNft, ...props }) => {

  return (
    <div className='cursor-pointer relative rounded-[6px] nft-card-detail' {...props}>
      <BackgroundBox bg={`/images/bg/nft-${dataNft?.attributes?.find(x => x?.traitType === 'Rare')?.value}.png`} className='nft-card-detail-bg'/>
      <Body className='nft-card-detail-body'>
        <InforText>
          <p className="sm:text-xs text-[10px] text-[#1C1B1D] font-semibold">Name:</p>
          <p className="sm:text-xs text-[10px] text-[#1C1A23] font-bold text-right">{dataNft?.name?.toLocaleUpperCase()}</p>
        </InforText>
        <InforText>
          <p className="sm:text-xs text-[10px] text-[#1C1B1D] font-semibold">ID:</p>
          <p className="sm:text-xs text-[10px] text-[#1C1A23] font-bold text-right">{`#${dataNft?.id}`}</p>
        </InforText>
        <Image src={dataNft?.image} preview={false} width="100%" height="100%" alt="" />
      </Body>
    </div>
  )
}

export default React.memo(NftCard)

const BackgroundBox = styled.div<{ bg: string }>`
  position: absolute;
  inset: 0;
  z-index: 0;
  content: ${({ bg }) => bg ? `url('${bg}')`: ""};
  width: 100%;
  height: 100%;
`

const Body = styled.div`
  position: relative;
  z-index: 1;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px;
    gap: 6px;
  }

  .ant-image {
    flex: 1;

    img {
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

const InforText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;

  position: relative;
  top: 4px;
`
