import { convertKeysToCamelCase } from 'helpers/CommonHelper'
import { fetchUserNft } from 'state/wallet/fetch/getUserNfts'
import useSWR, { SWRConfiguration } from 'swr'
import SWR_KEY from './constant'

export const useGetMyNftSwr = (account: string | undefined | null, options?: SWRConfiguration) => {
  const fetchData = async () => fetchUserNft(account).then(([res]: any) => res?.data?.data?.map(item => convertKeysToCamelCase(item)))

  return useSWR(account ? [SWR_KEY.GET_MY_NFT, account] : null, fetchData, {
    revalidateOnFocus: false,
    ...options,
  })
}
