import { createReducer } from '@reduxjs/toolkit'
import { DataMintType } from 'config/types/MintData'
import * as actions from './actions'

export interface ModalState {
  modalConnect: any
  modalBuyNow: { toggle: boolean; dataModal: any }
  modalRedeem: { toggle: boolean; dataModal: any }
  modalMintNft: { toggle: boolean; dataModal: { nft: DataMintType } }
  modalMyNft: { toggle: boolean; dataModal: any }
  modalAddNft: { toggle: boolean; dataModal: any; type: 'prize' | 'jackpot' | '' }
  modalBurnAndClaim: { toggle: boolean; type: 'prize' | 'jackpot' | '' }
  modalCongratulations: { toggle: boolean; type: 'prize' | 'jackpot' | '' }
  modalMintAnimation: { toggle: false }
}

const initialState: ModalState = {
  modalConnect: { toggle: false, dataModal: null },
  modalBuyNow: { toggle: false, dataModal: null },
  modalRedeem: { toggle: false, dataModal: null },
  modalMintNft: { toggle: false, dataModal: null },
  modalMyNft: { toggle: false, dataModal: null },
  modalAddNft: { toggle: false, dataModal: null, type: '' },
  modalBurnAndClaim: { toggle: false, type: '' },
  modalCongratulations: { toggle: false, type: '' },
  modalMintAnimation: { toggle: false },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setModalConnect, (state, { payload }) => {
      state.modalConnect = payload
    })
    .addCase(actions.setModalBuyNow, (state, { payload }) => {
      state.modalBuyNow = payload
    })
    .addCase(actions.setModalRedeem, (state, { payload }) => {
      state.modalRedeem = payload
    })
    .addCase(actions.setModalMintNft, (state, { payload }) => {
      state.modalMintNft = payload
    })
    .addCase(actions.setModalMyNft, (state, { payload }) => {
      state.modalMyNft = payload
    })
    .addCase(actions.setModalAddNft, (state, { payload }) => {
      state.modalAddNft = payload
    })
    .addCase(actions.setModalBurnClaim, (state, { payload }) => {
      state.modalBurnAndClaim = payload
    })
    .addCase(actions.setModalCongratulation, (state, { payload }) => {
      state.modalCongratulations = payload
    })
    .addCase(actions.setModalMintAnimation, (state, { payload }) => {
      state.modalMintAnimation = payload
    }),
)
