import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface CollectionState {
  nftListData: any
  nftMissedList: any
  nftUserData: any
  nftClaimData: any
}

const initialState: CollectionState = {
  nftListData: {
    data: [],
  },
  nftMissedList: null,
  nftUserData: {
    data: [],
  },
  nftClaimData: {
    data: {
      prize: null,
      jackpot: null,
    },
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setNftListData, (state, { payload }) => {
      state.nftListData.data = payload
    })
    .addCase(actions.setNftMissedList, (state, { payload }) => {
      state.nftMissedList = payload
    })
    .addCase(actions.setNftUserData, (state, { payload }) => {
      state.nftUserData.data = payload
    })
    .addCase(actions.setNftClaimData, (state, { payload }) => {
      const { type, data } = payload
      state.nftClaimData.data[type] = data
    }),
)
