import { createAction } from '@reduxjs/toolkit'

export const setModalConnect = createAction<any>('market/setModalConnect')
export const setModalBuyNow = createAction<any>('market/setModalBuyNow')
export const setModalRedeem = createAction<any>('modal/setModalRedeem')
export const setModalMintNft = createAction<any>('modal/setModalMintNft')
export const setModalMyNft = createAction<any>('market/setModalMyNft')
export const setModalAddNft = createAction<any>('market/setModalAddNft')
export const setModalBurnClaim = createAction<any>('market/setModalBurnClaim')
export const setModalCongratulation = createAction<any>('market/setModalCongratulation')
export const setModalMintAnimation = createAction<any>('market/setModalMintAnimation')
